import React from 'react'
import { graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography';
import Layout from '../layout'
import SEO from "../components/seo"

import Booking from "../components/property/booking"
import ImageHeader from "../components/property/image-header"
import MobileBooking from "../components/property/mobile-booking"
import Features from "../components/property/features"

import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types';
import { GoogleMap, LoadScript  } from '@react-google-maps/api'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sticky: {
    position: 'sticky',
    top: '20px',
    bottom: '20px'
  },
  details: {
    '& b': {
      color: 'rgba(0, 0, 0, 0.64)' }
  },
  amenities: { // https://github.com/contentful/rich-text/issues/101
    '& ul': {
      display: 'block',
      paddingInlineStart: '0'
    },
    '& li': { 
      display: 'inline',
      '& p': { 
        display: 'inline-block',
        paddingLeft: '20px',
        fontSize: '1.0rem'
       }
    }
  }
}));

const containerStyle = {
  width: '100%',
  height: '400px'
};

const Page = ({ data }) => {
  const classes = useStyles();
  const property = data.allContentfulProperty.edges[0].node
  const reviews = data.allContentfulReview.edges
  
  const center = {
    lat: property.geoLocation.lat,
    lng: property.geoLocation.lon
  };

  let Images = []
  property.propertyImages.map((image, i) => (
    Images.push({ original: image.file.url, thumbnail: image.resize.src})
  ))

  let Reviews = []
  reviews.map((review, i) => (
    Reviews.push(
      <Typography variant="body1" paragraph>
        &nbsp; &nbsp; {review.node.review.internal.content} <br />
        <Typography variant="subtitle2"> - {review.node.name}</Typography>
        <br />
      </Typography>
    )
  ))

  const contentfulOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography variant="body1" paragraph>{children}</Typography>
      ),
    },
  };

  return (
    <Layout>
      <SEO title={property.propertyName} />
      
      <ImageHeader images={property.propertyImages}/>

      <Container fixed style={{ minHeight: '100vh', marginBottom: '25px', paddingTop: '1rem' }}>

        <Typography variant="h4" component="h1" paragraph>{property.propertyName}</Typography>
        < hr />
        <div className={classes.root}>
          <Grid container spacing={3}>

            <Grid item md={8}>
              
              <Features property={property} />
              <hr />
              
              <div className={classes.details}>
                {documentToReactComponents(JSON.parse(property.description.raw), contentfulOptions)}
              </div>

              <Typography variant="h4" paragraph>Amenities</Typography>
              <div className={classes.amenities}>{documentToReactComponents(JSON.parse(property.amenities.raw))}</div>

              <Typography variant="h4" paragraph>Location</Typography>
              <LoadScript
                googleMapsApiKey="AIzaSyDQwv_TxYrfgKNiv_MY4S1PWLjbDZmUGh0"
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={12}
                >
                  { /* Child components, such as markers, info windows, etc. */}
                  <></>
                </GoogleMap>
              </LoadScript>

              <Typography variant="h4" paragraph style={{marginTop: '1rem'}}>Reviews</Typography>
              {Reviews}
            </Grid>

            <Hidden smDown>
              <Grid item md={4}>
                <div className={classes.sticky}>
                  <Booking property={property}/>
                </div>
              </Grid>
            </Hidden>
            
            <Hidden mdUp>
              <MobileBooking property={property}/>
            </Hidden> 

          </Grid>
        </div>
    
    </Container>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    allContentfulProperty(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          propertyName
          maxGuests
          numberOfBathrooms
          numberOfBedrooms
          costPerNight
          description { raw }
          amenities { raw }
          geoLocation { lat, lon }
          propertyImages { 
            title
            file {
              url
              fileName
              contentType
            }
            resize (height: 512) {
              src
            }
          }
        }
      }
    },
    allContentfulReview(filter: { property: {id: { eq: $id } } }) {
      edges {
        node {
          id
          name
          review {
            internal {
              content
            }
          }
        }
      }
    }
  }
`

export default Page