import React from "react"
import PropTypes from "prop-types"

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'

function Features({ property }) {

  return (
    <div>
      <Grid container>
        <Grid item md={3} xs={6}>
          <Typography variant="body1"><b>Bedrooms:</b> {property.numberOfBedrooms}</Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Typography variant="body1"><b>Bathrooms:</b> {property.numberOfBathrooms}</Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Typography variant="body1"><b>Max Guests:</b> {property.maxGuests}</Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Typography variant="body1"><b>Cost per night:</b> ${property.costPerNight}</Typography>
        </Grid>
      </Grid>
    </div>
  )

}

// Features.defaultProps = { }

Features.propTypes = {
  property: PropTypes.object.isRequired,
}

export default Features
