import React from "react"
import 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Booking = ({ property }) => {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date(Date.now()));
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(Date.now()));

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  return (
    <div className={classes.root}>

      <form className={classes.container} noValidate autoComplete="off" name="booking" method="POST" action="/contact-success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="booking" />
        <input type="hidden" name="property_id" value={property.id} />
        <input type="hidden" name="property_name" value={property.propertyName} />
        <input type="hidden" name="checkin" value={selectedStartDate} />
        <input type="hidden" name="checkout" value={selectedEndDate} />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Check In"
            value={selectedStartDate}
            onChange={handleStartDateChange}
            className={classes.textField}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Check Out"
            value={selectedEndDate}
            onChange={handleEndDateChange}
            className={classes.textField}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          id="name"
          name="Name"
          label="Name"
          required
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="phone"
          label="Phone"
          name="Phone"
          required
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="email"
          label="Email"
          name="Email"
          required
          className={classes.textField}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >
          Book
        </Button>
      </form>

    </div>
  )
};

export default Booking
