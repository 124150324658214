import React from "react"
import { makeStyles } from '@material-ui/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Lightbox from 'react-image-lightbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width: '100%',
  },
  lightboxImage: { cursor: 'pointer' },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  chevron: {
    position: 'absolute',
    right: 0,
    top: '25%',
    cursor: 'pointer',
  }
}));

const ImageHeader = (props) => {
  const classes = useStyles();

  const lightboxImages = []
  props.images.forEach(image => {
    lightboxImages.push(image.file.url)
  });

  const [photoIndex, setphotoIndex] = React.useState(0);
  const [isOpen, setisOpen] = React.useState(false);

  const theme = useTheme();
  const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
  const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
  const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
  const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const screenNarrow = useMediaQuery('(max-width:340px)');

  const getScreenWidth = () => {
    if (screenExtraLarge) {
      return 6;
    } else if (screenNarrow) {
      return 1;
    } else if (screenLarge) {
      return 6;
    } else if (screenMedium) {
      return 6;
    } else if (screenSmall) {
      return 2;
    } else if (screenExtraSmall) {
      return 2;
    } else {
      return 2;
    }
  }

  return (
    <div className={classes.root}>

      <GridList cellHeight={400} className={classes.gridList} cols={getScreenWidth()} spacing={2}>
        
        <GridListTile key={props.images[0].resize.src} cols={2}>
          <img src={props.images[0].resize.src} alt={props.images[0].title} className={classes.lightboxImage} onClick={() => setisOpen(true)} onKeyDown={() => setisOpen(true)}/>
        </GridListTile>

        <GridListTile key={props.images[1].resize.src} cols={2} className={classes.hideOnMobile}>
          <img src={props.images[1].resize.src} alt={props.images[1].title} className={classes.lightboxImage} onClick={() => setisOpen(true)} onKeyDown={() => setisOpen(true)}/>
        </GridListTile>

        <GridListTile key={props.images[3].resize.src} cols={1} className={classes.hideOnMobile}>
          <img src={props.images[3].resize.src} alt={props.images[3].title} className={classes.lightboxImage} onClick={() => setisOpen(true)} onKeyDown={() => setisOpen(true)}/>
        </GridListTile>

        <GridListTile key={props.images[4].resize.src} cols={1} className={classes.hideOnMobile}>
          <img src={props.images[4].resize.src} alt={props.images[4].title} className={classes.lightboxImage} onClick={() => setisOpen(true)} onKeyDown={() => setisOpen(true)}/>
        </GridListTile>

        <ChevronRightIcon className={classes.chevron} style={{ height: '5rem', width: '5rem' }} onClick={() => setisOpen(true)} onKeyDown={() => setisOpen(true)}/>

      </GridList>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
          prevSrc={lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]}
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + lightboxImages.length - 1) % lightboxImages.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + lightboxImages.length + 1) % lightboxImages.length)
          }
        />
      )}

    </div>
  )
};

export default ImageHeader
